<template>
    <b-row
        align-h="center"
    >
        <b-col
            lg="9"
            xl="8"
        >
            <wait-for-resource :resource="post">
                <h1>
                    {{ post.title }}
                </h1>

                <div class="small text-muted">
                    {{ post.created_at | dateFormat }}
                </div>

                <ow-img
                    class="my-5 w-100 rounded-lg"
                    :src="post"
                    src-key="cover_image.original"
                />

                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="post.body" />
            </wait-for-resource>
        </b-col>
    </b-row>
</template>

<script>
import {Post} from '@/models/Post';

export default {
    name: 'PostShow',
    data() {
        return {
            post: new Post({
                slug: this.$route.params.post,
            }),
        };
    },
    created() {
        this.post.fetch();
    },
    metaInfo() {
        return {
            title: this.post.title,
            titleTemplate: '%s',
        };
    },
};
</script>
